import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const StudentLogin= () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [userval, setuserval] = useState();
  const [redvalue, setredvalue] = useState("");
  let user = React.createRef();

  const handleMobileChange = (event) => {
    const result = event.target.value.replace(/\D/g, '');
    setMobile(result);
  }

  const handleSubmit =  (e) => {
   
    e.preventDefault();
    if (user.current.value === "" || user.current.value === null || user.current.value === undefined) {
      setuserval(true);
      setredvalue("Enter Mobile Number");
      return false;
  }
  
    var data = JSON.stringify({
      "mobile": user.current.value
    });
    const BASE_URL=window.BASE_URL+'/search_by_mobile';
    setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,
      maxBodyLength: Infinity,
      
      headers: { 
        'Content-Type': 'application/json',
      },
      data : data
    };
   
    axios.request(config)
    .then((response) => {
      if(response != null && response.data != null && response.data.results != null && response.data.results != undefined) {
      
        const moneyData = response.data.results;
         saveTokenInLocalStorage(response.data.token);
         navigate("/Student/Dashboard", { state: moneyData });
         document.getElementById('btnLogout').style.display = 'block';
         setIsLoading(false);
      }
      else if(response.data.code==401){
        setredvalue("Invalid Mobile Number");
        setIsLoading(false);
      }
      else{
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setredvalue(error.message);
      setIsLoading(false);
      console.log(error);
    });
  };
  const navigate = useNavigate();
    return (
        <div>
        <div className="loader" style={IsLoading ? {} : { display: 'none' }}>
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <div className="super-admin-container">
        <div className="super-admin-header">
            <h1>Login</h1>
        </div>
            <div className="form-group">
                <input type="text" placeholder="Phone Number" onChange={handleMobileChange} className="max-input" value={mobile} maxLength={10} ref={user} />
                <button className="max-button" onClick={handleSubmit}>LOGIN</button>
                <br />
                <p className="text-danger">{redvalue}</p>
            </div>
        </div>
        </div>
    );
};
export default StudentLogin;
export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem('jwtToken', (tokenDetails));
  }
