// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css'; // Your custom CSS if needed
import './Custom.css';
import './loader.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.js';
import LoginForm from "./StudentLogin";


import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
<Router>
  <App />
</Router>,
  document.getElementById('root')
);

reportWebVitals();
