import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

const ManageDashboard = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const moneyData = location.state;
  const [popupRechargeCardShow, setPopupRechargeCardShow] = useState(false);
  
  
  //Values
  const [CardNo, setCardNo] = useState('');
  const [StudentName, setStudentName] = useState('');
  const [Parent, setParent] = useState('');
  const [Relationship, setRelationship] = useState('');

  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
 
});
const fnRechargeClick = (bId, sId) => {

var data = JSON.stringify({
    "branch_id": bId,
  });
  const BASE_URL=window.BASE_URL+'/get_tittle';
  var config = {
    method: 'post',
    url: BASE_URL,

    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
      'Content-Type': 'application/json',
    },
    maxBodyLength: Infinity,
    data : data
  };
  axios.request(config)
  .then((response) => {
    if(response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results != undefined) {
        let tempData = [...moneyData];
         let findData = tempData.find(selected=> selected.student_id === sId)
         setCardNo(findData.card_name);
         setStudentName(findData.student_name)
         setParent(findData.parent_name);
         setRelationship(findData.parent_relation);
         setPopupRechargeCardShow(true);
    }    
  })
  .catch((error) => {
    console.log(error);
  });
}

const fnRechargeCardClose = () => {
  setPopupRechargeCardShow(false);
}

const fnSubmitClick = () => {
}

function fnRchargeAmtChange(){

};
    return (
        <div className="super-admin-container">
            <div className="super-admin-header">
                <h1>Student Details</h1>
            </div>

{/* Recharge popup */}
      <Modal show={popupRechargeCardShow} onHide={fnRechargeCardClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Recharge Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
     {/* <table>
     <tbody>
        {RechargeDetails.map((rCharge, idx) => (
              <tr className="text-center" id="addr0" key={idx}>
                  <td>{rCharge.card_name}</td>
                  <td>{rCharge.student_name}</td>
                  <td>{rCharge.parent_name}</td>
                  <td>{Data.parent_relation}</td>
              </tr>
          ))}
    </tbody>
     </table> */}
      <div>Card No.:</div><div><label>{CardNo}</label></div> <br></br>
      <div>Student Name:</div><div>{StudentName}</div> <br></br>
      <div>Parent:</div><div>{Parent}</div> <br></br>
      <div>Relationship:</div><div>{Relationship}</div><br></br>

      <div>Recharge Amount:</div><div><select name="rchargeAmt" onChange={fnRchargeAmtChange}>
                                                            <option>10</option>
                                                            <option>20</option>
                                                            <option>30</option>
        </select></div>
      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={fnSubmitClick}> Submit </Button>
        </Modal.Footer>
      </Modal>
            <div className="">
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th>Card Number</th>
                                    <th>Student Name</th>
                                    <th>Parent</th>
                                    <th>Relationship</th>
                                    <th>Balance</th>
                                    <th>Recharge Click</th>
                                </tr>
                            </thead>
                            <tbody>
                                {moneyData.map((Data, idx) => (
                                    <tr className="text-center" id="addr0" key={idx}>
                                        <td>{Data.card_name}</td>
                                        <td>{Data.student_name}</td>
                                        <td>{Data.parent_name}</td>
                                        <td>{Data.parent_relation}</td>
                                        <td>{Data.balance}</td>
                                       <td><button variant="primary" onClick={() => fnRechargeClick(Data.branch_id, Data.student_id)}>Recharge Now</button></td> 
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
            </div>
    );
};

export default ManageDashboard;