// App.js
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginForm from "./StudentLogin";
import Dashboard from './Student/Dashboard';


function App() {
  const [IsLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [btnlogoutshow, setBtnlogoutshow] = useState(false);

  const navigate = useNavigate();
  const logout = () => {
      document.getElementById('btnLogout').style.display = 'none';
      console.log(localStorage.removeItem('jwtToken'));
      localStorage.removeItem('jwtToken');
      console.log(localStorage.removeItem('jwtToken'));
      navigate("/");
  };
  useEffect(() => {
    setBtnlogoutshow(false);
    setUserName(localStorage.Loginname);
    document.getElementById('btnLogout').style.display = 'none';
});
  return (
    
    //  <div>
    //   <LoginForm />
    //   <RegistrationForm />
    
    //  </div>
      <div id="menuoff" className="handle-leftNav">
           <div className="left-nav">
                {/* <a href="..\Dashboard"><img src={loginlogo} width="55px" /></a> */}

                <ul className="navbar-nav me-auto mb-2 mt-3 mb-lg-0">
                    {/* <li className="nav-item">
    //                     <a className="nav-link" href="..\Dashboard">
    //                         <i class='fas fa-signal'></i>  Dashboard
    //                     </a>
    //                 </li> */}
                    {/* <li className="nav-item">
    //                     <a className="nav-link" href="..\ApprovalList">
    //                         <i class='fas fa-signal'></i>  Approval Client Access
    //                     </a>
    //                 </li> */}
                    {/* <li className="nav-item">
    //                     <a className="nav-link" href="..\DeltaProcess">
    //                         <i class='far fa-newspaper'></i> Delta NIK
    //                     </a>
    //                 </li> */}
                 </ul>

            </div>
           {/* <div className="nav-top text-end p-2" id="header">
               <a className="nav-link user-tile" href="#" onClick={logout}>
                 <span className="user-name">{userName}</span>
                    <i className="fas fa-power-off"></i>
                </a>
           </div> */}

            <div className="right-container">
          <Routes>
               <Route exact index path="/" element={<LoginForm />} />
               <Route path="/Student/Dashboard" element={<Dashboard />} />
         </Routes>
              </div>
             <center><div id="btnLogout"><button onClickCapture={logout}>Logout</button></div></center>
           <footer><center ><font size="2"><i class="fa-solid fa-copyright"></i> Max Card Payment | version 1.0 | {new Date().getFullYear()}</font>  </center></footer>
        </div>
  );
}

export default App;
